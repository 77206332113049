import React from 'react';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import ExploreSlider from '@components/pageModules/modules/exploreSlider';
import { Link } from 'gatsby';

const ThankYouPage = () => {
  return (
    <Layout>
      <PageMeta
        metaTitle='Thank You | 2025 Season'
        metaDescription='Thank you for signing up for the 2025 season.'
      />
      <div className='pt-space-l  relative z-1 h-[50svh] text-teal  font-season  w-full justify-center  flex flex-col items-center text-left gap-8 max-w-5xl mx-auto'>
        <div className='space-y-5'>
          <h1 className='blockH1'>Kia ora,</h1>
          <p>
            Thank you so much for your ticket bookings to our 2025 Season, we’re
            thrilled you will be taking this musical journey with us.
            <br />
            <br />
            From here we will be processing your booking with a view to having
            your tickets out to you before the end of the year.
            <br />
            <br />
            If you would like to contact us, please call{' '}
            <a href='tel:08002662378'>0800 266 2378</a> or email
            <a href='mailto:info@chambermusic.co.nz'>
              {' '}
              info@chambermusic.co.nz
            </a>
            <br />
            <br />
            Ngā mihi nui,
            <br />
            <br />
            Michelle Walsh - CEO
          </p>
          <Link to='/' className='btn-standard bg-teal inline-block'>
            Return to homepage
          </Link>
        </div>
      </div>
      <div className='pb-space-m pt-space-m'>
        <ExploreSlider />
      </div>
    </Layout>
  );
};

export default ThankYouPage;
